.coupon{
    margin: 10px;
    font-family: var(--heading-font);
}
.coupon .coupon-head{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}
.coupon .coupon-head p{
    color: rgb(52, 52, 52);
    font-size: 22px;
    font-weight: 500;
    letter-spacing: 1px;
    font-family: var(--heading-font);
}
.coupon .coupon-head button{
    cursor: pointer;
    padding: 8px 10px;
    scale: 0.9;
    border: none;
    background-color: black;
    color: white;
    font-weight: 500;
    font-size: 11px;
    letter-spacing: 1px;
    box-shadow: 0 0px 0px 0 rgba(86, 86, 86, 0.3), 0 3px 5px 0 rgba(101, 101, 101, 0.3);
}
.coupon .coupon-list{
    display: grid;
    grid-template-columns: repeat(4,1fr);
    grid-gap: 10px;
}
.coupon .coupon-list .coupon-card{
    padding: 5px;
    display: grid;
    grid-template-columns: 1fr 3fr;
    grid-gap: 10px;
    background-color: white;
    cursor: pointer;
}
.coupon .coupon-list .coupon-card:hover{
    box-shadow: 0 2px 4px 0 rgba(121, 121, 121, 0.2), 0 3px 10px 0 rgba(105, 105, 105, 0.19);
    scale: 1.01;
}
.coupon .coupon-list .coupon-card .left{
    width: 100%;
    height: 1005;
    background-color: rgba(211, 211, 211, 0.726);
    padding:5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.coupon .coupon-list .coupon-card .left p{
    font-size: 18px;
    font-weight: 600;
    color: black;
}
.coupon .coupon-list .coupon-card .right{
    position: relative;
}
.coupon .coupon-list .coupon-card .right .status{
    position: absolute;
    top:-5px;
    right:-5px;
    background-color: rgba(156, 255, 156, 0.473);
    color: green;
    padding: 2px 3px;
    
}
.coupon .coupon-list .coupon-card .right p{
    color: gray;
    font-size: 12px;
    margin-bottom: 5px;
    font-weight: 500;
}
.coupon .coupon-list .coupon-card .right .name{
    font-weight: 600;
    color: black;
}
.coupon .coupon-list .coupon-card .right .date{
    font-size: 12px;
}


.coupon .coupon-make{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    

}
.coupon .coupon-make .center-div{
    width: 50%;
    background-color: white;
    padding: 20px 10px;
    border-radius: 10px;
    box-shadow: 0 2px 4px 0 rgba(121, 121, 121, 0.2), 0 3px 10px 0 rgba(105, 105, 105, 0.19);
}
.coupon .coupon-make div{
    margin-bottom: 15px;
}
.coupon .coupon-make div p{
    font-size: 13px;
    font-weight: 500;
    margin-bottom: 5px;
    color: rgb(60, 60, 60);
}
.coupon .coupon-make div .alert{
    font-size: 11px;
    color: blue;
}
.coupon .coupon-make div input,.coupon .coupon-make div select{
    width: 100%;
    padding: 12px 5px;
    border-radius: 5px;
    border: 1px solid rgb(160, 160, 160);
    background-color: rgba(238, 238, 238, 0.726);
}
@media screen and (max-width:1250px) {
    .coupon .coupon-list{
        grid-template-columns: repeat(3,1fr);
    }
}
@media screen and (max-width:950px) {
    .coupon{
        margin: 10px 5px;
    }
    .coupon .coupon-list{
        grid-template-columns: repeat(2,1fr);
        grid-gap: 5px;
    }
}
@media screen and (max-width:650px) {
    .coupon .coupon-list{
        grid-template-columns: repeat(1,1fr);
    }
}