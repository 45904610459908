.header{
    position: relative;
    height: 55px;
    background-color: white;
    z-index: 1000;
    max-width: 100vw;
}
.header .head1 .admin{
    display: flex;
    align-items: center;
    cursor: pointer;
    font-weight: 500;
    font-size: 18px;
}
.header .head1 .admin span:nth-child(2){
    margin-left: 5px;
    text-transform: uppercase;
    font-weight: 600;
}

.header .head1{
    padding: 8px 30px;
    display: grid;
    grid-template-columns: 1fr 3fr 1fr;
    align-items: center;
    position: fixed;
    min-width: 100%;
    top: 0;
    background-color: white;
}
.header .head1 button{
    border: none;
    border-radius: 5px;
    padding: 3px;
    background-color: transparent;
}
.header .head1 .left{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.header .logo{
    width: 150px;
}
.header .logo2{
    display: none;
}
.header .head1 .center{
    display: flex;
    justify-content: center;
    align-items: center;
}
.header .head1 .center .search{
    width: 80%;
    display: flex;
    align-items: center;
    border: 1px solid grey;
    padding: 2px 5px;
    position: relative;
}
.search .s-result{
    position: absolute;
    top: 40px;
    border: 1px solid black;
    padding: 10px;
    width: 100%;
    left: 0;
    background-color: white;
    max-height: 300px;
    overflow-y: auto;
}
.search .s-result .sval{
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    cursor: pointer;
}
.search .s-result .sval img{
    border: 1px solid gray;
    border-radius: 5px;
    width: 50px;
    height: 50px;
    margin-right: 10px;
}
.search .s-result .sval p{
    margin-right: 10px;
    font-weight: 500;
    font-size: 15px;
}
.header .head1 .center .search input{
    width:90%;
    outline: none;
    border: none;
    margin-left: 10px;
}
.header .head1 .center .search input::placeholder{
    font-family: var(--heading-font);
}

.header .head1 .right{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}
.header .head1 .right img{
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-left: 10px;
    position: relative;
    cursor: pointer;
}
.header .head1 .right .logout{
    position: absolute;
    bottom: -70px;
    background-color: white;
    padding: 10px;
    border-radius: 5px;
    right: 30px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    transition: all 0.3s;


}
.header .head1 .right .logout li{
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    cursor: pointer;
}
.header .head1 .right .logout li .log-icon{
    margin-right: 5px;
    font-size: 20px;
}
.header .head2{
    width: 250px;
    position: fixed;
    top: 54px;
    transition-duration: 700ms;
    transition-timing-function: ease;    
    padding: 10px 25px;
    height: calc(100vh - 53px);
    background-color: rgb(235, 235, 235);
    font-family: var(--heading-font);
    box-shadow: 0px 15px 25px -10px rgba(115,115,115,0.75);
}
.header .head2 div{
    margin-top: 10px;
   
}
.header #pagination-example{
    background-color: white;

}

.header #pagination-example div{
    background-color: white;
    display: flex;
    align-items: center;
    padding: 10px;
    z-index: 1000;
}
.header #pagination-example img{
    min-width: 50px !important;
    display: block;
    height: 50px;
    border: 1px solid black;
}
.header #pagination-example div span{
    text-wrap: nowrap;
    margin-left: 10px;
    color: black;
}
.header .head2 div button{
    padding-left: 0%;
}
.header .head2 ul li{
    margin-bottom: 10px;
    background-color: white;
    padding: 5px 10px;
    letter-spacing: 1px;
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 13px;
    text-transform: uppercase;
}
.header .head2 ul li a{
    margin-left: 10px;

}
.header .head2 ul li .liIcon{
    margin-right: 5px;
    background-color: black;
    color: white;
    border-radius: 50%;
    padding: 4px;
    font-size: 20px;
    
}

.overlay {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); 
    z-index:0
}
@media screen and (max-width:950px) {
    

    .header .logo2{
        display: block;
    }
    .header .logo1{
        display: none;
    }
    .header .head1{
        padding: 8px 20px;
        display: grid;
        grid-template-columns: 1fr 4fr 1fr;
        align-items: center;
    }
    .header .head1 .admin{
        font-size: 16px;
    }
}
@media screen and (max-width:650px) {
    .header .head1{
        padding: 8px 10px;
        display: grid;
        grid-template-columns: 1fr 5fr 1fr;
        align-items: center;
    }
    .header #pagination-example img{
        min-width: 30px !important;
        display: block;
        height: 30px;
        border: 1px solid black;
    }
    .header #pagination-example div span{
        text-wrap: nowrap;
        margin-left: 10px;
        color: black;
        font-size: 11px;
    }
    .header .head1 .admin{
        font-size: 11px;
    }
}