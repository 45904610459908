*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    scroll-behavior: smooth;
    font-family: var(--heading-font);
}
li{
    list-style: none;
}
a{
    text-decoration: none;
}
li a{
    color: black;
    text-decoration: none;
    box-shadow: none;
}
Link h2{
    text-decoration: none;
}
a{
    text-decoration: none;
}
li a:hover{
    box-shadow: none;
}
.container{
    margin:0 50px;
}
div a:hover{
 box-shadow: none;
}
div a{
    text-decoration: none;
}
.page-loading{
    position: fixed;
    top: 0px;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 10;
}
.page-loading .loader{
    width: 100%;
    height: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    padding-top: 30vh;
    text-align: center;
}
.page-loading .loader img{
    width: 130px;
    
}
.page-loading .loader p{
    font-size: 20px;
    letter-spacing: 1px;
    margin-top: 10px;
}