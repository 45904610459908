.banner{
    margin: 20px;
    font-family: var(--heading-font);
}
.banner .banner-head{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
}
.banner .image .image-second{
    display: grid;
    grid-template-columns: 1fr 1fr;
}
.banner .banner-head p{
    color: rgb(52, 52, 52);
    font-size: 22px;
    font-weight: 500;
    letter-spacing: 1px;
    font-family: var(--heading-font);
}
.banner .banner-head button{
    cursor: pointer;
    padding: 8px 10px;
    scale: 0.9;
    border: none;
    background-color: black;
    color: white;
    font-weight: 500;
    font-size: 11px;
    letter-spacing: 1px;
    box-shadow: 0 0px 0px 0 rgba(74, 74, 255, 0.300), 0 3px 5px 0 rgba(74, 74, 255, 0.300);
}
.banner .banner-list{
    display: grid;
    grid-template-columns: repeat(7,1fr);
    grid-gap: 20px;
}
.banner .banner-list .banner-card{
    border-radius: 8px;
    padding: 5px;
    display: grid;
    grid-template-columns:1fr;
    grid-gap: 10px;
    background-color: white;
    cursor: pointer;
}
.banner .banner-list .banner-card:hover{
    box-shadow: 0 2px 4px 0 rgba(121, 121, 121, 0.2), 0 3px 10px 0 rgba(105, 105, 105, 0.19);
    scale: 1.03;
}
.banner .banner-list .banner-card .left{
    width: 100%;
    height: 100%;
    background-color: rgba(221, 255, 161, 0.726);
    border-radius: 5px;
    padding:5px;
    display: flex;
    /* flex-direction: column; */
    justify-content: center;
    align-items: center;
}
.banner .banner-list .banner-card .left img{
    width: 50%;
    aspect-ratio: 1/1;
    margin: 2px;
}
.banner .banner-list .banner-card .right{
    position: relative;
    text-align: center;
}
.banner .banner-list .banner-card .right .status{
    position: absolute;
    top:0;
    right:0;
    background-color: rgba(156, 255, 156, 0.473);
    color: green;
    padding: 2px 3px;
    
}
.banner .banner-list .banner-card .right p{
    color: gray;
    font-size: 15px;
    margin-bottom: 5px;
    font-weight: 500;
}
.banner .banner-list .banner-card .right .name{
    font-weight: 600;
    color: black;
}
.banner .banner-list .banner-card .right .date{
    font-size: 12px;
}


.banner .banner-make{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    

}
.banner .banner-make .center-div{
    width: 50%;
    background-color: white;
    padding: 20px 10px;
    border-radius: 10px;
    box-shadow: 0 2px 4px 0 rgba(121, 121, 121, 0.2), 0 3px 10px 0 rgba(105, 105, 105, 0.19);
}
.banner .banner-make div{
    margin-bottom: 15px;
}
.banner .banner-make div p{
    font-size: 13px;
    font-weight: 500;
    margin-bottom: 5px;
    color: rgb(60, 60, 60);
}
.banner .banner-make div .alert{
    font-size: 11px;
    color: blue;
}
.banner .banner-make div input,.banner .banner-make div select{
    width: 100%;
    padding: 12px 5px;
    border-radius: 5px;
    border: 1px solid rgb(160, 160, 160);
    background-color: rgba(238, 238, 238, 0.726);
}
.banner .banner-page{
    background-color: white;
    padding: 10px;
    border-radius: 10px;
}
.banner .banner-page div{
    margin-bottom: 15px;
}
.banner .banner-page div p{
    margin-bottom: 2px;
    font-size: 13px;
    font-weight: 500;
}
.banner .banner-page .image{
    width: 200px;
    text-align: center;
    aspect-ratio: 1/1;
    border: 1px solid rgb(216, 216, 216);
    border-radius: 5px;

}
.banner .banner-page .image .second-div{
    position: relative;
}
.banner .banner-page .image .img-box-in{
    width: 100%;
    aspect-ratio: 1/1;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    cursor: pointer;

}
.banner .banner-page .image img{
    width: 100%;
    aspect-ratio: 1/1;
    border-radius: 5px;
}
.banner .banner-page .image .delete{
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    
}
.banner .banner-page input{
    width: 100%;
    padding: 8px;
    border-radius: 8px;
    border: none;
    background-color: rgb(235, 235, 235);

}
.banner .banner-page select{
    width: 100%;
    padding: 8px;
    border-radius: 8px;
    border: none;
    background-color: rgb(235, 235, 235);


}
.banner .banner-page textarea{
    width: 100%;
    padding: 8px;
    border-radius: 8px;
    height: 150px;
    border: none;
    background-color: rgb(235, 235, 235);


}
@media screen and (max-width:1550px) {
    .banner .banner-list{
        grid-template-columns: repeat(5,1fr);
        grid-gap: 15px;
    }
}
@media screen and (max-width:1250px) {
    .banner .banner-list{
        grid-template-columns: repeat(4,1fr);
        grid-gap: 15px;
    }
}
@media screen and (max-width:950px) {
    .banner .banner-list{
        grid-template-columns: repeat(3,1fr);
        grid-gap: 10px;
    }
}
@media screen and (max-width:650px) {
    .banner .banner-list{
        grid-template-columns: repeat(2,1fr);
        grid-gap: 8px;
    }
}
