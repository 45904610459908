.abandoneds{
    padding: 10px;
}
.abandoneds .head{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.abandoneds .product-table{
    width: 100%;
    margin-top: 5px;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 15px;
    max-height: 90vh;
    overflow-y: scroll;

}
.abandoneds .product-table::-webkit-scrollbar{
    display: none;
}
.abandoneds .product-table .product{
    background-color: white;
    padding: 5px;
    position: relative;
}
.abandoneds .product-table .product .state{
    position: absolute;
    right: 0;
    top: 0;
    letter-spacing: 1px;
    padding: 2px 3px;
    text-align: left;
    font-size: 10px;
}
.abandoneds .product-table .product .delete{
    position: absolute;
    right: 0;
    bottom: 0;    
    display: flex;
    align-items: center;
}
.abandoneds .product-table .product .delete p{
    margin-left: 15px;
    padding: 2px 3px;
    font-size: 23px;
    color: rgb(255, 91, 91);
    cursor: pointer;
}
.abandoneds .product-table .product .delete p:nth-child(1){
color: green;
}
.abandoneds .product-table .product .paid{
    background-color: rgba(156, 255, 156, 0.473);
    color: green;

}
.abandoneds .product-table .product .cod{
    background-color: rgba(255, 253, 156, 0.473);
    color: rgb(128, 126, 0);

}
.abandoneds .product-table .product .cancel{
    background-color: rgba(255, 156, 156, 0.473);
    color: rgb(128, 0, 0);

}
.abandoneds .product-table .product:hover{
    box-shadow: 0 4px 8px 0 rgba(121, 121, 121, 0.2), 0 6px 20px 0 rgba(105, 105, 105, 0.19);
    scale: 1.01;

}
.abandoneds .product-table .product .product-img{
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 4fr;
    margin-bottom: 5px;
    grid-gap: 5px;

}
.abandoneds .product-table .product .product-img img{
    width: 80px;
    aspect-ratio: 1/1;
    border: 1px solid rgb(185, 185, 185);
}
.abandoneds .product-table .product span{
    font-weight: 600;
    margin-right: 5px;
    color: black;
}
.abandoneds .product-table .product p{
    font-family: var(--heading-font);
    padding: 2px;
    font-size: 11px;
    color: rgb(104, 104, 104);
}
.abandoneds .product-table .product .number{
    font-weight: bold;
}
.abandoneds .product-table .product .time{
    font-size: 11px;
}
.abandoneds .product-table .product .name{
    text-transform: uppercase;
}
.abandoneds .product-table .product .amount{
    font-size: 11px;
}
.abandoneds .product-table .product .phone{
    color: rgb(11, 21, 139);
    font-weight: 600;
    padding-bottom: 5px;

}
.abandoneds .paginate{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 30px 0;
}
.abandoneds .paginate button{
    width: 70px;
    border: none;
    background-color: rgb(72, 72, 255);
    color: white;
    border-radius: 5px;
    padding: 5px 0;
    font-size: 16px;
    cursor: pointer;
}
.abandoneds .paginate p{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 15px;
    width: 30px;
    height: 30px;
    border: 1px solid rgb(171, 171, 171);
    border-radius: 50%;

}

.single-order{
    margin: 20px;
    font-family: var(--heading-font);
}
.single-order .order-head{
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}
.single-order .order-head .left{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
.single-order .order-head .left .number{
    font-size: 25px;
    font-weight: 600;
    color: rgb(67, 67, 67);
}
.single-order .order-head .left .number span{
    color: rgb(130, 130, 130);
}
.single-order .order-head .left .date{
    font-size: 13px;
    color: rgb(67, 67, 67);
    display: flex;
    align-items: center;
    margin-left: 15px;
}

.single-order .order-head .left .date .date-icon{
    font-size: 15px;
    margin-right:5px;
}
.single-order .order-head .left .status{
    font-size: 11px;
    font-weight: 500;
    padding: 3px 7px;
    border-radius: 5px;
    background-color:rgba(255, 209, 124, 0.591);
    color: rgb(170, 111, 1);
}
.single-order .order-head .left .type{
    font-size: 11px;
    font-weight: 500;
    padding: 3px 7px;
    border-radius: 5px;
    background-color: rgba(156, 255, 156, 0.473);
    color: green;
}

.single-order .order-head .left .cod{
    background-color: rgba(252, 255, 156, 0.473);
    color: rgb(128, 124, 0);
}
.single-order .order-head .left .cancel{
    background-color: rgba(255, 156, 156, 0.473);
    color: rgb(128, 0, 0);
}
.single-order .order-head .left p{
    margin-right: 15px;
}
.single-order .order-head .right{
    display: flex;
    align-items: center;
}

.single-order .order-head .right .btn{
    background-color: black;
    color: white;
    padding: 6px 10px;
    margin-right: 15px;
    border: none;
    letter-spacing: 1px;
}
.single-aban input{
padding: 8px 5px;
margin-left: 10px;
border: 1px solid gray;
width: 100%;
}
.single-aban .email span{
    font-weight: 500;
}

.single-order .order-detail{
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-gap: 20px;
}
.single-order .order-detail .left-section .order-products{
    background-color: white;
    border-radius: 5px;
    padding: 15px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);

}
.single-order .order-detail .left-section .order-products .prdt{
    border-bottom: 1px solid rgb(231, 231, 231);
    padding: 5px 0;
}
.single-order .order-detail .left-section .order-products .prdt .left{
    display: grid;
    grid-template-columns: 0.7fr 2fr 1fr 0.5fr 1fr;
    grid-gap: 10px;
}
.single-order .order-detail .left-section .order-products .prdt .left img{
    width: 90px;
    aspect-ratio: 1/1;
    border: 1px solid rgb(227, 227, 227);
}
.single-order .order-detail .left-section .order-products .prdt .left .details p:nth-child(1){
    font-weight: 600;
    font-size: 13px;
    letter-spacing: 1px;
    margin-bottom: 7px;
    margin-right: 10px;
    color: rgb(48, 48, 48);
}
.single-order .order-detail .left-section .order-products .prdt .left .details p{
    color: rgb(115, 115, 115);
    font-size: 13px;
    font-weight: 600;
}
.single-order .order-detail .left-section .order-products .prdt .left .details p span{
    font-size: 13px;
    font-weight: 500;
    margin-right: 5px;
}
.single-order .order-detail .left-section .order-products .prdt .left .total{
    font-weight: 600;
    font-size: 13px;
    color: rgb(48, 48, 48);
    text-align: right;
}
.single-order .order-detail .left-section .order-products .prdt .left .price{
    font-weight: 600;
    font-size: 13px;
    color: rgb(186, 0, 0);
    text-align: right;
}
.single-order .order-detail .left-section .order-products .prdt .left .qty{
    font-weight: 500;
    font-size: 13px;
    color: rgb(48, 48, 48);
    text-align: right;
}
.single-order .order-detail .left-section .payment-info{
    background-color: white;
    border-radius: 5px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);

    padding: 15px;
    margin-top: 20px;
}
.single-order .order-detail .left-section .payment-info .head{
    font-size: 17px;
    font-weight: 600;
    margin-bottom: 20px;
}

.single-order .order-detail .left-section .payment-info .payment{
    display: grid;
    grid-template-columns: 2fr 1fr 2fr;
    grid-gap: 10px;
}.single-order .order-detail .left-section .payment-info .payment p{
    margin-bottom: 10px;
}.single-order .order-detail .left-section .payment-info .payment .center{
    text-align: center;
    font-size: 14px;
    font-weight: 500;
}
.single-order .order-detail .left-section .payment-info .payment .right{
    font-weight: 600;
    text-align: right;
    color: rgb(40, 40, 40);
}.single-order .order-detail .right-section{
    background-color: white;
    border-radius: 5px;
    padding: 10px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);

}
.single-order .order-detail .right-section .right-head{
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 15px;
}
.single-order .order-detail .right-section .customer-name{
    margin: 10px 0;
    display: flex;
    align-items: center;
    font-weight: 500;
    letter-spacing: 1px;
    font-size: 14px;
}
.single-order .order-detail .right-section .customer-name img{
    width: 30px;
    margin-right: 10px;
}
.single-order .order-detail .right-section .customer-abandoneds{
    margin: 10px 0;
    display: flex;
    align-items: center;
    font-weight: 500;
    letter-spacing: 1px;
    font-size: 14px;
}
.order-icon{
    width: 35px;
    color: blue;
    background-color: rgba(192, 192, 255, 0.562);
    height: 30px;
    border-radius: 50%;
    padding: 5px;
    margin-right: 10px;

    
}
.single-order .order-detail .right-section .email{
    margin-top: 25px;
}
.single-order .order-detail .right-section .email p{
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    font-size: 13px;
}



.dialog .dialog-content{
    min-width: 45vw;
}
.dialog .search-dialog{
   
    display: flex;
    align-items: center;
    background-color: rgb(241, 241, 241);
    border-radius: 5px;
    padding: 8px;
}
.dialog .search-dialog .search-icon{
    font-size: 18px;
    margin-right: 5px;
    color: rgb(166, 166, 166);
}
.dialog .search-dialog input{
    outline: none;
    border: none;
    background: transparent;
    width: 100%;
}
.dialog .product-list{
    height: 300px;
    overflow-y: scroll;
    padding: 10px;
    font-family: var(--heading-font);
    
}
.dialog .product-list .product-main{
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}
.dialog .product-list .list-product{
    padding: 10px 0;
    border-bottom: 1px solid rgb(220, 220, 220);
}
.dialog .product-list .list-product .product-drop{
    margin-left: 60px;
    margin-top: 20px;
}
.dialog .product-list .list-customer{
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    grid-gap: 10px;
    padding: 10px;
    border-bottom: 1px solid rgb(219, 219, 219);
    font-size: 12px;
    font-weight: 500;
    cursor: pointer;
}
.dialog .product-list .list-product .product-drop ul li{
    margin-bottom: 10px;
    cursor: pointer;
    font-size: 13px;
    display: grid;
    grid-template-columns:0.2fr 2fr 1fr 1fr;
    border-bottom: 1px solid rgb(220, 220, 220);
    grid-gap: 10px;
    padding: 5px 0;
}
.dialog .product-list .product-main img{
    width: 50px;
    height: 50px;
    border-radius: 5px;
    border: 1px solid gray;
}
.dialog .product-list .product-main .drop-icon{
    margin-right: 10px;
    width: 20px;
    height: 20px;
    /* background-color: rgb(232, 232, 232); */
    border: 1px solid gray;
    display: flex;
    justify-content: center;
    align-items: center;
    color: gray;
    border-radius: 50%;
}

.dialog .product-list .product-main .drop-icon:hover{
    box-shadow: 0 2px 4px 0 rgba(121, 121, 121, 0.2), 0 3px 10px 0 rgba(92, 92, 92, 0.19);

}

.dialog .product-list .product-main div{
    display: flex;
    align-items: center;
    margin-right: 10px;
}

.dialog .product-list .product-main div p{
    font-size: 12px;
    font-weight: 600;
    margin-left: 10px;
}

.single-order .message{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 15px;
    margin: 50px 0;
}
.single-order .message .msg{
    border: 1px solid rgb(210, 210, 210);
    padding: 10px 5px;
    border-radius: 5px;

}
.single-order .message .msg .comments{
    margin-top: 20px;
}
.single-order .message .msg .comments p{
    font-size: 15px;
    font-weight: 500;
}
.single-order .message .msg .comments .comment-box{
    background-color: white;
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 10px;
}
.single-order .message .msg .comments .comment-box .msg-c{
    margin-top: 10px;
    font-weight: 400;

}
.single-order .message .msg .comments .comment-box .name{
    display: flex;
    align-items: center;
}
.single-order .message .msg .comments .comment-box .name .time{
    margin-left: 15px;
    font-size: 13px;
    
}
.single-order .history{
    border: 1px solid rgb(210, 210, 210);
    padding: 10px 5px;
    border-radius: 5px;
}
.single-order .history .history-box{
    background-color: white;
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 10px;
}
.single-order .history .history-box .msg-c{
    margin-top: 10px;
    font-weight: 400;

}
.single-order .history .history-box .name{
    display: flex;
    align-items: center;
}
.single-order .history .history-box .name .time{
    margin-left: 15px;
    font-size: 13px;
    
}
.single-order .message .form{
    background-color: white;
    padding: 10px;
    margin: 10px 0;
}
.single-order .message .form input{
    padding: 8px;
    background-color: rgb(238, 238, 238);
    border-radius: 8px;
    border: none;
    outline: none;
    width: 100%;
    margin: 10px 0;
}
.single-order .message .form button{
    padding: 8px 20px;
    cursor: pointer;
    scale: 0.9;
    border: none;
    background-color: rgb(74, 74, 255);
    border-radius: 5px;
    color: white;
    font-weight: 500;
    font-size: 11px;
    letter-spacing: 1px;
    box-shadow: 0 0px 0px 0 rgba(74, 74, 255, 0.300), 0 3px 5px 0 rgba(74, 74, 255, 0.300);
}


@media screen and (max-width:1450px) {
    .abandoneds .product-table{
        padding: 5px 0;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 10px;

    
    }
}
@media screen and (max-width:1250px) {
    .abandoneds .product-table{
        padding: 5px 0;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 15px;

    
    }
}
@media screen and (max-width:950px) {
    .abandoneds .product-table{
        padding: 5px 0;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 10px;

    
    }
    .single-order .order-head{
        flex-direction: column;
    }
    .single-order .order-head .right .btn{
        padding: 6px 5px;
        margin-right: 5px;
        letter-spacing: 1;
        font-size: 10px;
        margin-top: 10px;
    }
    .single-order .order-detail{
        grid-template-columns:1fr;
    }
}
@media screen and (max-width:650px) {
    .abandoneds .product-table{
        padding: 5px 0;
        grid-template-columns: repeat(1, 1fr);
        grid-gap:15px;

    
    }
}
