.customer{
    max-width: 100vw;
    margin: 20px;
    font-family: var(--heading-font);
}
.customer .customer-head{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
}
.customer .customer-head p{
    color: rgb(52, 52, 52);
    font-size: 22px;
    font-weight: 500;
    letter-spacing: 1px;
    font-family: var(--heading-font);
}
.customer .customer-head button{
    cursor: pointer;
    padding: 8px 10px;
    scale: 0.9;
    border: none;
    background-color: rgb(74, 74, 255);
    border-radius: 5px;
    color: white;
    font-weight: 500;
    font-size: 11px;
    letter-spacing: 1px;
    box-shadow: 0 0px 0px 0 rgba(74, 74, 255, 0.300), 0 3px 5px 0 rgba(74, 74, 255, 0.300);
}

.customer .customer-list{
    width: 100%;
    background-color: white;
    border-radius: 5px;
    padding: 15px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);
    overflow-y: scroll;
    height: 80vh;

}
.customer .customer-list .table{
    width: 100%;
    
}
.customer .customer-list .table table{
    width: 100%;
    text-align: left;
    font-size: 12px;
    border-collapse: collapse;
    
    

}
table a{
    color: rgb(53, 53, 53);

}
.customer .customer-list .table table .thead{
    color: rgb(72, 72, 72);
    margin-bottom: 20px;
}
.customer .customer-list .table table tr{
    border-bottom: 1px solid grey;
    display: grid;
    grid-template-columns: 0.5fr 2fr 3fr 1fr 1fr 1fr 1fr;
    padding: 10px 0;

}
.customer .paginate{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 30px 0;
}
.customer .paginate button{
    width: 70px;
    border: none;
    background-color: rgb(72, 72, 255);
    color: white;
    border-radius: 5px;
    padding: 5px 0;
    font-size: 16px;
    cursor: pointer;
}
.customer .paginate p{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 15px;
    width: 30px;
    height: 30px;
    border: 1px solid rgb(171, 171, 171);
    border-radius: 50%;

}
.customer .customer-list .table table tr span{
    display: none;
}

@media screen and (max-width:950px) {
    .customer .customer-list .table table .thead{
        display: none;
    }
    .customer .customer-list .table table tbody tr{
        display: flex;
        flex-direction: column;
        
    }
    .customer .customer-list .table table tr td{
        display: flex;
        align-items: center;
    }
    .customer .customer-list .table table tr span{
        display: block;
        font-weight:600;
        margin-right: 5px;
    }
}
