.blogs{
    margin: 20px;
    font-family: var(--heading-font);
}
.blogs input{
    width: 100%;
    padding: 8px;
    border: none;
    border-radius: 5px;
    background-color: rgb(235, 235, 235);
}
.blogs select{
    width: 100%;
    padding: 8px;
    border: none;
    border-radius: 5px;
    background-color: rgb(235, 235, 235);
}
.blogs textarea{
    width: 100%;
    padding: 8px;
    height: 150px;
    border: none;
    border-radius: 5px;
    background-color: rgb(235, 235, 235);

}
.blogs .blogs-head{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
}
.blogs .blogs-head p{
    color: rgb(52, 52, 52);
    font-size: 22px;
    font-weight: 500;
    letter-spacing: 1px;
    font-family: var(--heading-font);
}
.blogs .blogs-head button{
    cursor: pointer;
    padding: 8px 10px;
    scale: 0.9;
    border: none;
    background-color: rgb(74, 74, 255);
    border-radius: 5px;
    color: white;
    font-weight: 500;
    font-size: 11px;
    letter-spacing: 1px;
    box-shadow: 0 0px 0px 0 rgba(74, 74, 255, 0.300), 0 3px 5px 0 rgba(74, 74, 255, 0.300);
}
.blogs .blog-data{
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-gap: 15px;
}
.blogs .blog-data .blog-desc{
    height: 400px;
}
.blogs .blog-data .descr{
    height: 300px;
}
.blogs .blog-data p{
    font-size: 14px;
    margin-bottom: 2px;
    font-weight: 500;
}
.blogs .blog-data .left1{
    background-color: white;
    border-radius: 5px;
    padding: 15px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);
}
.blogs .blog-data .left1 div{
    margin-bottom: 15px;
}
.blogs .blog-data .left2 div{
    margin-bottom: 15px;
}
.blogs .blog-data .blog-right div{
    margin-bottom: 20px;
}
.blogs .blog-data .left2{
    background-color: white;
    margin-top: 20px;
    border-radius: 5px;
    padding: 15px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);
}
.blogs .blog-data .blog-right{
    background-color: white;
    border-radius: 5px;
    padding: 15px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);
}
.blogs .blog-data .blog-right .image{
    width: 100%;
    aspect-ratio: 1/1;
    border: 1px solid rgb(216, 216, 216);
    border-radius: 5px;
    position: relative;

}
.blogs .blog-data .blog-right .image .img-box-in{
    width: 100%;
    aspect-ratio: 1/1;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    cursor: pointer;

}
.blogs .blog-data .blog-right .image img{
    width: 100%;
    aspect-ratio: 1/1;
    border-radius: 5px;
}
.blogs .blog-data .blog-right .image .delete{
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    
}
.blogs .blog-list{
    margin-top: 20px;
    display: grid;
    grid-template-columns: repeat(4,1fr);
    grid-gap: 10px;
    align-items: center;
}
.blogs .blog-list .blog-card{
    border-radius: 8px;
    padding: 5px;
    display: grid;
    grid-template-columns: 1fr 3fr;
    grid-gap: 10px;
    background-color: white;
    cursor: pointer;
    align-items: center;
}
.blogs .blog-list .blog-card:hover{
    box-shadow: 0 2px 4px 0 rgba(121, 121, 121, 0.2), 0 3px 10px 0 rgba(105, 105, 105, 0.19);
    scale: 1.03;
}
.blogs .blog-list .blog-card img{
    width: 100%;
    aspect-ratio: 1/1;
    border: 5px;
    border: 1px solid rgb(211, 211, 211);
}

.blogs .blog-list .blog-card .title{
    font-weight: 600;
    font-size: 14px;
}
.blogs .blog-list .blog-card p{
    font-size: 13px;
    color: gray;
    margin-bottom: 3px;
}
.blogs .blog-list .blog-card .desc{
    height: 30px;
    text-wrap: wrap;
    overflow: hidden;
}
