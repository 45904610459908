.history{
    margin: 20px;
}
.history .section-heading{
    font-size: 25px;
    margin-bottom: 10px;
    font-weight: 500;
}
.history .section-body{
    background-color: white;
    border-radius: 10px;
    padding: 15px;
}
.history .section-body .hist{
    display: grid;
    grid-template-columns: 1fr 4fr 3fr 1.5fr;
    grid-gap: 10px;
    font-size: 14px;
    padding: 5px 0;
    border-bottom: 1px solid rgb(142, 142, 142);
}

.history .paginate{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 30px 0;
}
.history .paginate button{
    width: 70px;
    border: none;
    background-color: rgb(72, 72, 255);
    color: white;
    border-radius: 5px;
    padding: 5px 0;
    font-size: 16px;
    cursor: pointer;
}
.history .paginate p{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 15px;
    width: 30px;
    height: 30px;
    border: 1px solid rgb(171, 171, 171);
    border-radius: 50%;

}

@media screen and (max-width:650px) {
    .history .section-body .hist{
        font-size: 10px;
    }
}
