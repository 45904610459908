.reviews{
    margin: 20px;
}
.review-head{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}
.review-head p{
    font-weight: 500;
    font-size: 25px;
}
.review-head button{
    padding: 8px 20px;
    cursor: pointer;
    scale: 0.9;
    border: none;
    background-color: rgb(74, 74, 255);
    border-radius: 5px;
    color: white;
    font-weight: 500;
    font-size: 11px;
    letter-spacing: 1px;
    box-shadow: 0 0px 0px 0 rgba(74, 74, 255, 0.300), 0 3px 5px 0 rgba(74, 74, 255, 0.300);
}

.reviews .ratings{
    display: grid;
    grid-template-columns: repeat(6,1fr);
    grid-gap: 10px;
}
.reviews .ratings .rating{
    background-color: white;
    word-wrap: break-word;
    cursor: pointer;
}
.reviews .ratings .rating .detail{
    padding: 10px 5px;
}
.reviews .ratings .rating img{
    width: 100%;
    aspect-ratio: 1/1;
}
.reviews .ratings .rating .name{
    font-size: 12px;
    font-weight: 600;
}
.reviews .ratings .rating .stars{
    margin: 2px 0;
}
.reviews .ratings .rating .msg{
    margin-top: 10px;
    font-size: 12px;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* Number of lines to show */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 3.8em; 
}


.add-review{
    margin: 20px;
}
@media screen and (max-width:1250px) {
    .reviews .ratings{
        grid-template-columns: repeat(4,1fr);
    }
}
@media screen and (max-width:950px) {
    .reviews .ratings{
        grid-template-columns: repeat(3,1fr);
    }
}
@media screen and (max-width:650px) {
    .reviews .ratings{
        grid-template-columns: repeat(2,1fr);
    }
    .reviews .ratings .rating .name{
        font-size: 10px;
        font-weight: 600;
    }
}