.collection{
    margin: 20px;
    font-family: var(--heading-font);
}
.collection .collection-head{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}
.collection .collection-head p{
    color: rgb(52, 52, 52);
    font-size: 22px;
    font-weight: 500;
    letter-spacing: 1px;
    font-family: var(--heading-font);
}
.collection button{
    cursor: pointer;
    padding: 8px 10px;
    scale: 0.9;
    border: none;
    background-color: black;
    color: white;
    font-weight: 500;
    font-size: 11px;
    letter-spacing: 1px;
    box-shadow: 0 0px 0px 0 rgba(116, 116, 116, 0.3), 0 3px 5px 0 rgba(103, 103, 103, 0.3);
}
.collection .collection-list{
    display: grid;
    grid-template-columns: repeat(6,1fr);
    grid-gap: 10px;
}
.collection .collection-list .collection-card{
    padding: 2px;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 10px;
    background-color: white;
    cursor: pointer;
}
.collection .collection-list .collection-card:hover{
    box-shadow: 0 2px 4px 0 rgba(121, 121, 121, 0.2), 0 3px 10px 0 rgba(105, 105, 105, 0.19);
    scale: 1.03;
}
.collection .collection-list .collection-card .left{
    width: 100%;
    height: 100%;
    background-color: rgba(226, 226, 226, 0.726);
    padding:2px;
    display: flex;
    flex-wrap: wrap;
    grid-template-columns: auto 1fr;
    justify-content: center;
    align-items: center;
    grid-gap: 5px;
}
.collection .collection-list .collection-card .left img{
    max-width: 48%;
    /* aspect-ratio: 1/1; */
}
.collection .collection-list .collection-card .right{
    position: relative;
}
.collection .collection-list .collection-card .right .status{
    position: absolute;
    top:0;
    right:0;
    background-color: rgba(156, 255, 156, 0.473);
    color: green;
    padding: 2px 3px;
    
}
.collection .collection-list .collection-card .right p{
    color: gray;
    font-size: 12px;
    margin-bottom: 5px;
    font-weight: 500;
}
.collection .collection-list .collection-card .right .name{
    font-weight: 600;
    color: black;
}
.collection .collection-list .collection-card .right .date{
    font-size: 12px;
}


.collection .collection-make{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    

}
.collection .collection-make .center-div{
    width: 50%;
    background-color: white;
    padding: 20px 10px;
    border-radius: 10px;
    box-shadow: 0 2px 4px 0 rgba(121, 121, 121, 0.2), 0 3px 10px 0 rgba(105, 105, 105, 0.19);
}
.collection .collection-make div{
    margin-bottom: 15px;
}
.collection .collection-make div p{
    font-size: 13px;
    font-weight: 500;
    margin-bottom: 5px;
    color: rgb(60, 60, 60);
}
.collection .collection-make div .alert{
    font-size: 11px;
    color: blue;
}
.collection .collection-make div input,.collection .collection-make div select{
    width: 100%;
    padding: 12px 5px;
    border-radius: 5px;
    border: 1px solid rgb(160, 160, 160);
    background-color: rgba(238, 238, 238, 0.726);
}
.collection .collection-page{
    background-color: white;
    padding: 10px;
    border-radius: 10px;
    display: flex;
    flex-wrap: wrap;
    grid-gap: 20px;
    align-items: center;
    width: 100%;
}
.collection .collection-page div{
    margin-bottom: 15px;
    width: 100%;
}
.collection .collection-page div p{
    margin-bottom: 2px;
    font-size: 13px;
    font-weight: 500;
}
.collection .collection-page .image{
    width: 30vw;
    border: 1px solid rgb(216, 216, 216);
    border-radius: 5px;
    position: relative;

}
.collection .collection-page .image .img-box-in{
    width: 100%;
    aspect-ratio: 1/1;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    cursor: pointer;

}
.collection .collection-page .image img{
    width: 100%;
    /* aspect-ratio: 1/1; */
    border-radius: 5px;
}
.collection .collection-page .image .delete{
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    
}
.collection .collection-page input{
    width: 100%;
    padding: 8px;
    border-radius: 8px;
    border: none;
    background-color: rgb(235, 235, 235);

}
.collection .collection-page select{
    width: 100%;
    padding: 8px;
    border-radius: 8px;
    border: none;
    background-color: rgb(235, 235, 235);


}
.collection .collection-page textarea{
    width: 100%;
    padding: 8px;
    border-radius: 8px;
    height: 100px;
    border: none;
    background-color: rgb(235, 235, 235);


}

.collection .products{
    background-color: white;
    padding: 10px;
    border-radius: 10px;
    margin-top: 15px;

}
.collection .products .button{
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.collection .products .header{
    display: grid;
    grid-template-columns: 1fr 5fr 1fr 0.5fr 1fr;
    padding: 5px 0;
    margin-bottom: 10px;
    border-bottom: 1px solid grey;
    align-items: center;
    font-weight: 500;
    z-index: 1;
}
.collection .products .prdt{
    display: grid;
    grid-template-columns: 1fr 5fr 1fr 0.5fr 1fr;
    padding: 5px 0;
    margin-bottom: 10px;
    border-bottom: 1px solid grey;
    align-items: center;
}

.collection .products .prdt img{
    width: 70px;
    height: 70px;
    border-radius: 5px;
    border: 1px solid grey;
}
@media screen and (max-width:1250px) {
    .collection .collection-list{
        grid-template-columns: repeat(3,1fr);
        grid-gap: 15px;
    }

}
@media screen and (max-width:950px) {
    .collection{
        margin: 5px;
    }
    .collection .collection-page .image{
        width: 45%;
    }
    .collection .collection-list{
        grid-template-columns: repeat(2,1fr);
        grid-gap: 10px;
    }
    .collection .collection-page{
        grid-template-columns: 1fr;
    }
}
@media screen and (max-width:650px) {
    .collection .collection-page .image{
        width: 95%;
    }
    .collection .collection-list{
        grid-template-columns: repeat(1,1fr);
        grid-gap: 8px;
    }
    .collection .collection-page{
        grid-template-columns: 1fr;
    }
    .collection .products .header{
        grid-template-columns: 1fr 4fr 1fr 1fr 1fr;
        grid-gap: 20px;

    }
    .collection .products .header p{
        font-size: 12px;
    }
    .collection .products .prdt{
        grid-template-columns: 1fr 4fr 1fr 1fr 1fr;
        grid-gap: 20px;
        font-size: 10px;
    }
    .collection .products .prdt .title{
        font-size: 10px;
    }
    .collection .products .prdt .qty{
        font-size: 10px;
    }
    .collection .products .prdt img{
        width: 50px;
        height: 50px;
      
    }
}
