@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Source+Serif+4:ital,opsz,wght@0,8..60,200..900;1,8..60,200..900&display=swap');
:root{
    --paragraph-font:"Source Serif 4", serif;
    --heading-font:"Montserrat", sans-serif;
    --bg-color:rgb(216, 216, 216);
    --price-color:#740c0c;
    --button-bg:rgba(243, 243, 243, 0.863);
    
}


body{
    background-color: rgba(226, 226, 255, 0.658);
       
    
}
.heading{
    font-size: 22px;
    font-weight: 600;
    letter-spacing: 2px;
    margin-bottom: 20px;
}
.btn{
    padding: 7px;
    background-color:black;
    border: 2px solid black;
    color: white;
    font-weight: 500;
    letter-spacing: 2px;
    cursor: pointer;
    transition: all 0.5s;
}
.btn:hover{
    background-color: transparent;
    color: black;
}
.mini-btn{
    background-color: var(--button-bg);
    border: none;
    border-radius: 5px;
    padding: 3px;
    cursor: pointer;
}