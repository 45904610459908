.logins{
    max-width: 100vw;
    height: 100vh;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
}
.logins .login-box{
    width: 320px;
    max-height: 70vh;
    background-color: rgb(255, 255, 255);
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.logins .login-box .login-details , .logins .login-box .signup-details{
 width: 100%;
}
.logins input{
    margin-bottom: 20px;
    width: 100%;
    padding: 10px;
    border-radius: 3px;
    border: none;
    background-color: rgba(240, 240, 240, 0.758);
}
.logins p{
    text-align: center;
    font-size: 25px;
    letter-spacing: 1px;
    font-weight: 600;
    margin-bottom: 20px;
    text-transform: uppercase;
}
.logins .login-box .submit{
    background-color: black;
    color: white;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 1px;
    font-size: 19px;
    cursor: pointer;
}