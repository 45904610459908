.my-team{
    padding: 20px 10px;
    width: 100%;
}
.my-team .head{
    margin-bottom: 20px;
    font-weight: 600;
    font-size: 25px;
}
.my-team .team{
    display: grid;
    grid-template-columns: repeat(6,1fr);
    grid-gap: 10px;
    align-items: center;
    width: 100%;
}
.my-team .team .team-card{
    background-color: white;
    padding: 10px;
    border-radius: 5px;
    width: 100%;
    text-align: center;
    cursor: pointer;
}
.my-team .team .team-card:hover{
    opacity: 0.8;
    cursor: pointer;
}
.my-team .team .team-card p{
    font-size: 14px;
    margin-bottom: 8px;
    text-wrap: wrap;
}
.my-team .team .team-card img{
    width: 100%;
    aspect-ratio: 1/1;
    margin-bottom: 10px;
}
.my-team .team .team-card .name{
    font-weight: 500;
    text-transform: uppercase;
}
.my-team .infos{
    position: fixed;
    top: 50px;
    left: 0;
    width: 100%;
    height: calc(100vh - 50px);
    background-color: rgba(0, 0, 0, 0.477);
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(2px);
transition: all 0.3s;
}
.my-team .infos .delete{
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.my-team .infos .information{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
    background-color: white;
    padding: 10px;
    width: 70%;
    /* height: 90%; */
}
.my-team .infos .information input{
    width: 100%;
    margin-bottom: 20px;
    padding: 10px;
    border: 1px solid gray;
    border-radius: 5px;
}
.my-team .infos .information .left .image{
border: 1px solid gray;
width: 100%;
aspect-ratio: 1/1;
padding: 5px;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
position: relative;
}
.my-team .infos .information .left .image .delete{
    position: absolute;
    top: 0;
    right: 0;
}
.my-team .infos .information .left .image img{
    width: 100%;
    aspect-ratio: 1/1;
}
.my-team .infos .information .left .image input{
    width: 100%;
    aspect-ratio: 1/1;
}
.my-team .infos .information .right button{
    padding: 8px 15px;
    background-color: black;
    color: white;
    font-weight: 500;
    letter-spacing: 1px;
    border: none;
}

@media screen and (max-width:950px) {
    .my-team .team{
        grid-template-columns: repeat(3,1fr);
        grid-gap: 10px;
    }
}

@media screen and (max-width:650px) {
    .my-team{
        padding: 20px 5px;
    }
    .my-team .team .team-card{
        padding: 5px;
         border-radius: 2px;
    }
    .my-team .team{
        grid-template-columns: repeat(2,1fr);
        grid-gap: 5px;
    }
    .my-team .infos .information{
        grid-template-columns: 1fr;
        width: 280px;
    }
    .my-team .infos .delete{
        top: 5px;
        right: 2px;
        font-size: 12px;
    }
    .my-team .infos .information input{
        margin-bottom: 10px;
    }
}