.products{
    padding:5px 10px;
}
.products .head{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.products .filter button{
    margin-top: 10px;
    border: none;
    background-color: rgb(46, 46, 46);
    color: white;
    padding: 3px 5px;
    font-size: 16px;
    cursor: pointer;
    margin-right: 10px;
    
}
.products .filter select{
    padding: 3px 5px;
    width: 150px;
    border: 2px solid rgb(46, 46, 46);

}

.products .product-table{
    padding: 10px 0;
    width: 100%;
    margin-top: 10px;
    padding: 5px;
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    grid-gap: 10px;
    height: 90vh;
    overflow-y: scroll;

}
.products .product-table::-webkit-scrollbar{
    display: none;
}
.products .product-table .product{
    background-color: white;
}
.products .product-table .product:hover{
    box-shadow: 0 4px 8px 0 rgba(121, 121, 121, 0.2), 0 6px 20px 0 rgba(105, 105, 105, 0.19);
    scale: 1.01;

}
.products .product-table .product .product-img{
    width: 100%;
    aspect-ratio: 1/1;

}
.products .product-table .product .product-img img{
    width: 100%;
    height: 100%;
    border-radius: 8px;
    background-color: white;
}
.products .product-table .product p{
    font-family: var(--heading-font);
}
.products .product-table .product .title{
    font-size: 12px;
    color: rgb(45, 45, 45);
    padding: 3px;
    height: 48px;
    text-wrap: wrap;
    font-weight: 600;
    overflow: hidden;
}
.products .product-table .product .sku{
    font-size: 11px;
    font-weight: bold;
    color: rgb(23, 49, 197);
    padding: 3px;
    height: 20px;
    overflow: hidden;
    text-align: center;
}

.products .product-table .product .inventory{
    font-size: 14px;
    padding: 3px;
    font-weight: 600;
    text-align: center;
}
.products .product-table .product .state{
    font-size: 15px;
    margin: 5px;
    padding: 0px 5px;
    color: white;
    letter-spacing: 1px;
    text-align: center;
    width: 100px;
    margin: 0 auto;
    text-transform: uppercase;
}
.products .product-table .product .category{
    font-size: 15px;
    color: rgb(0, 0, 0);
    padding: 3px;
    text-align: center;
}
.products .paginate{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 30px 0;
}
.products .paginate button{
    width: 70px;
    border: none;
    background-color: rgb(72, 72, 255);
    color: white;
    border-radius: 5px;
    padding: 5px 0;
    font-size: 16px;
    cursor: pointer;
}
.products .paginate p{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 15px;
    width: 30px;
    height: 30px;
    border: 1px solid rgb(171, 171, 171);
    border-radius: 50%;

}

@media screen and (max-width:1450px) {
    .products .product-table{
        padding: 5px 0;
        grid-template-columns: repeat(8, 1fr);
        grid-gap: 15px;

    
    }
}
@media screen and (max-width:1250px) {
    .products .product-table{
        padding: 5px 0;
        grid-template-columns: repeat(6, 1fr);
    
    }
}
@media screen and (max-width:950px) {
    .products .product-table{
        padding: 5px 0;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 5px;

    
    }
}
@media screen and (max-width:650px) {
    .products .product-table{
        padding: 5px 0;
        grid-template-columns: repeat(3, 1fr);
    
    }
}