.orders{
    padding:5px 10px;
}
.orders .marks{
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
}
.orders .marks .oks{
    font-size: 24px !important;
    cursor: pointer;
    margin-left: 10px;
}
.orders .marks .ico1{
    color: rgb(0, 131, 0);
}
.orders .marks .ico2{
    color: rgb(255, 57, 57);
}

.orders .head{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid gray;
}

.single-order .alls{
    margin: 10px 0;
    border: 1px solid rgb(214, 214, 214);
    padding: 8px;
}
.single-order .alls p{
    margin-bottom: 10px;
    font-weight: 500;
    cursor: pointer;
    font-size: 15px;
    border: 1px solid rgb(200, 200, 200);
    padding: 5px 2px;
    border-radius: 5px;
}
.orders .searchs p{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 5px;
    cursor: pointer;
    color: white;
}
.orders .search1{
width: 50%;
display: flex;
align-items: center;
background-color: black;

}
.orders .search2{
    margin-top: 10px;
    width: 97%;
    display: flex;
align-items: center;
background-color: black;
display: none;

}
.orders .searchs input{
    background-color: rgb(229, 229, 229);
    color: black;
    z-index: 9;
    width: 100%;
}

.orders .searchs input{
    padding: 8px;
    width: 100%;
    background-color: white;
    border: none;
}
.orders .down{
    display: flex;
    align-items: center;
}
.orders .down div{
    margin-left: 10px;
    border: 1px solid grey;
}
.orders .down div button{
    border-radius: 0;
    padding: 10px 5px;
    border: none;
    cursor: pointer;
    background-color: black;
    color: white;

}
.orders .down div input{
    width: 50px;
    padding: 10px 0;
    border: none;
}
.orders .product-table{
    width: 100%;
    margin-top: 10px;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 15px;
    max-height: 90vh;
    overflow-y: scroll;

}
.orders .product-table::-webkit-scrollbar{
    display: none;
}
.orders .product-table .product{
    background-color: white;
    padding:2px;
    position: relative;
}
.orders .product-table .product .state{
    position: absolute;
    right: 0px;
    top: 0px;
    letter-spacing: 1px;
    padding: 2px 3px;
    text-align: left;
    font-size: 10px;
    font-weight: 500;


}
.orders .product-table .product .paid{
    background-color: rgba(156, 255, 156, 0.473);
    color: green;

}
.orders .product-table .product .cod{
    background-color: rgba(255, 253, 156, 0.473);
    color: rgb(77, 75, 0);

}
.orders .product-table .product .cancel{
    background-color: rgba(255, 156, 156, 0.473);
    color: rgb(128, 0, 0);

}
.orders .product-table .product:hover{
    box-shadow: 0 4px 8px 0 rgba(121, 121, 121, 0.2), 0 6px 20px 0 rgba(105, 105, 105, 0.19);
    scale: 1.01;

}
.orders .product-table .product .product-img{
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 4fr;
    margin-bottom: 2px;
    grid-gap: 5px;

}
.orders .product-table .product .product-img img{
    width: 80px;
    aspect-ratio: 1/1;
    border: 1px solid rgb(185, 185, 185);
}
.orders .product-table .product span{
    font-weight: 600;
    margin-right: 5px;
    color: black;
}
.orders .product-table .product p{
    font-family: var(--heading-font);
    padding: 2px;
    font-size: 11px;
    color: rgb(83, 83, 83);
}
.orders .product-table .product .number{
    font-weight: bold;
}
.orders .product-table .product .time{
    font-size: 11px;
}
.orders .product-table .product .name{
    text-transform: uppercase;
}
.orders .product-table .product .amount{
    font-size: 11px;
}
.orders .product-table .product .phone{
    color: rgb(11, 21, 139);
    font-weight: 600;
    padding-bottom: 5px;

}
.orders .paginate{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 30px 0;
}
.orders .paginate button{
    width: 70px;
    border: none;
    background-color: rgb(72, 72, 255);
    color: white;
    border-radius: 5px;
    padding: 5px 0;
    font-size: 16px;
    cursor: pointer;
}
.orders .paginate p{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 15px;
    width: 30px;
    height: 30px;
    border: 1px solid rgb(171, 171, 171);
    border-radius: 50%;

}

.single-order{
    margin: 10px;
    font-family: var(--heading-font);
}
.single-order .order-head{
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}
.single-order .order-head .left{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
.single-order .order-head .left .number{
    font-size: 25px;
    font-weight: 600;
    color: rgb(67, 67, 67);
}
.single-order .order-head .left .number span{
    color: rgb(130, 130, 130);
}
.single-order .order-head .left .date{
    font-size: 13px;
    color: rgb(67, 67, 67);
    display: flex;
    align-items: center;
    margin-left: 15px;
}

.single-order .order-head .left .date .date-icon{
    font-size: 15px;
    margin-right:5px;
}
.single-order .order-head .left .stati{
    font-size: 11px;
    font-weight: 500;
    padding: 3px 7px;
    border-radius: 5px;
}
.single-order .status{
   
    background-color:rgba(255, 253, 124, 0.591);
    color: rgb(170, 162, 1);
}
.single-order .status1{
    background-color:rgba(255, 209, 124, 0.591);
    color: rgb(170, 111, 1);
}
.single-order .status2{
    background-color:rgba(124, 255, 124, 0.591);
    color: rgb(1, 170, 4);
}
.single-order .order-head .left .type{
    font-size: 11px;
    font-weight: 500;
    padding: 3px 7px;
    border-radius: 5px;
    background-color: rgba(156, 255, 156, 0.473);
    color: green;
}

.single-order .order-head .left .cod{
    background-color: rgba(252, 255, 156, 0.473);
    color: rgb(128, 124, 0);
}
.single-order .order-head .left .cancel{
    background-color: rgba(255, 156, 156, 0.473);
    color: rgb(128, 0, 0);
}
.single-order .order-head .left p{
    margin-right: 15px;
}
.single-order .order-head .right{
    display: flex;
    align-items: center;
}
.single-order .order-head .right button:nth-child(1){
    background-color: rgba(201, 201, 201, 0.541);
    border: 1px solid rgb(0, 0, 0);
    color: rgb(28, 28, 28);
    border-radius: 5px;
    padding: 4px 4px;
    margin-right: 15px;
}
.single-order .order-head .right button:nth-child(2){
    background-color: black;
    color: white;
    border-radius: 5px;
    padding: 6px 10px;
    margin-right: 15px;
    border: none;
    letter-spacing: 1px;
}
.single-order .order-head .right button:nth-child(1) .icon{
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.single-order .order-detail{
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-gap: 20px;
}
.single-order .order-detail .left-section .order-products{
    background-color: white;
    padding: 15px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);

}
.single-order .order-detail .left-section .order-products .prdt{
    border-bottom: 1px solid rgb(231, 231, 231);
    padding: 5px 0;
}
.single-order .order-detail .left-section .order-products .prdt .left{
    display: grid;
    grid-template-columns: 0.7fr 2fr 1fr 0.5fr 1fr;
    grid-gap: 10px;
}
.single-order .order-detail .left-section .order-products .prdt .left img{
    width: 90px;
    aspect-ratio: 1/1;
    border: 1px solid rgb(227, 227, 227);
}
.single-order .order-detail .left-section .order-products .prdt .left .details p:nth-child(1){
    font-weight: 600;
    font-size: 13px;
    letter-spacing: 1px;
    margin-bottom: 7px;
    margin-right: 10px;
    color: rgb(48, 48, 48);
}
.single-order .order-detail .left-section .order-products .prdt .left .details p{
    color: rgb(115, 115, 115);
    font-size: 13px;
    font-weight: 600;
}
.single-order .order-detail .left-section .order-products .prdt .left .details p span{
    font-size: 13px;
    font-weight: 500;
    margin-right: 5px;
}
.single-order .order-detail .left-section .order-products .prdt .left .total{
    font-weight: 600;
    font-size: 13px;
    color: rgb(48, 48, 48);
    text-align: right;
}
.single-order .order-detail .left-section .order-products .prdt .left .price{
    font-weight: 600;
    font-size: 13px;
    color: rgb(186, 0, 0);
    text-align: right;
}
.single-order .order-detail .left-section .order-products .prdt .left .qty{
    font-weight: 500;
    font-size: 13px;
    color: rgb(48, 48, 48);
    text-align: right;
}
.single-order .order-detail .left-section .payment-info{
    background-color: white;
    border-radius: 5px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);

    padding: 15px;
    margin-top: 20px;
}
.single-order .order-detail .left-section .payment-info .head{
    font-size: 17px;
    font-weight: 600;
    margin-bottom: 20px;
}

.single-order .order-detail .left-section .payment-info .payment{
    display: grid;
    grid-template-columns: 2fr 1fr 2fr;
    grid-gap: 10px;
}.single-order .order-detail .left-section .payment-info .payment p{
    margin-bottom: 10px;
}.single-order .order-detail .left-section .payment-info .payment .center{
    text-align: center;
    font-size: 14px;
    font-weight: 500;
}
.single-order .order-detail .left-section .payment-info .payment .right{
    font-weight: 600;
    text-align: right;
    color: rgb(40, 40, 40);
}.single-order .order-detail .right-section{
    background-color: white;
    border-radius: 5px;
    padding: 10px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);

}
.single-order .order-detail .right-section .right-head{
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 15px;
}
.single-order .order-detail .right-section .customer-name{
    margin: 10px 0;
    display: flex;
    align-items: center;
    font-weight: 500;
    letter-spacing: 1px;
    font-size: 14px;
}
.single-order .order-detail .right-section .customer-name img{
    width: 30px;
    margin-right: 10px;
}
.single-order .order-detail .right-section .customer-orders{
    margin: 10px 0;
    display: flex;
    align-items: center;
    font-weight: 500;
    letter-spacing: 1px;
    font-size: 14px;
}
.order-icon{
    width: 35px;
    color: blue;
    background-color: rgba(192, 192, 255, 0.562);
    height: 30px;
    border-radius: 50%;
    padding: 5px;
    margin-right: 10px;

    
}
.single-order .order-detail .right-section .email{
    margin-top: 25px;
}
.single-order .order-detail .right-section .email p{
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    font-size: 13px;
}


.new-order{
    margin: 20px;
    font-family: var(--heading-font);
}
.new-order .head{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}
.new-order .head p{
    font-weight: 500;
    font-size: 15px;
}
.new-order .order-data{
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-gap: 15px;
}
.new-order .order-data .products{
    background-color: white;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);

}
.new-order .order-data .products .search{
    display: flex;
    align-items: center;
    width: 100%;
    background-color: rgba(245, 245, 245, 0.726);
    padding: 8px;
    border-radius: 8px;
    border: 1px solid rgb(50, 50, 50);
}
.new-order .order-data .products .search .search-icon{
    font-size: 19px;
    color: rgb(122, 122, 122);
    margin-right: 8px;
}
.new-order .order-data .products .search input{
    width: 95%;
    background-color: transparent;
    border: none;
    outline: none;
}
.new-order .order-data .products .headings{
    display: grid;
    grid-template-columns: 3fr 1fr 1fr;
    grid-gap: 10px;
    font-size: 13px;
    font-weight: 600;
    margin-bottom: 10px;
    border-bottom: 1px solid rgb(203, 203, 203);
    padding-bottom: 10px;

}
.new-order .order-data .products .product{
    margin: 15px 0;
}
.new-order .order-data .products .product-data{
    display: grid;
    grid-template-columns: 3fr 1fr 1fr;
    grid-gap: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid rgb(203, 203, 203);
    padding-bottom: 10px;
}
.new-order .order-data .products .product-data div:nth-child(1){
    display: grid;
    grid-template-columns: 1fr 4fr;
    grid-gap: 10px;
    font-size: 14px;

}
.new-order .order-data .products .product-data div:nth-child(1) img{
    width: 100%;
    aspect-ratio: 1/1;
    border: 1px solid rgb(149, 149, 149);
    border-radius: 5px;

}
.new-order .order-data .products .product-data div:nth-child(1) .title{
    font-weight: 600;
    margin-bottom: 8px;
}
.new-order .order-data .products .product-data div:nth-child(1) .size{
    font-weight: 400;
    margin-bottom: 8px;
    font-size: 12px;

}
.new-order .order-data .products .product-data div:nth-child(1) .sku{
    font-weight: 400;
    margin-bottom: 8px;
    font-size: 12px;

}
.new-order .order-data .products .product-data div:nth-child(1) .price{
    font-weight: 400;
    margin-bottom: 8px;
    font-size: 12px;

}
.new-order .order-data .products .product-data div:nth-child(2) input{
    width: 70%;
    padding: 8px 10px;
    font-weight: 600;
    border: 1px solid rgb(149, 149, 149);
    border-radius: 5px;

}
.new-order .order-data .products .product-data div:nth-child(3){
    display: flex;
    justify-content: space-between;

}
.new-order .order-data .products .product-data div:nth-child(3) p .delete-icon{
    margin-left: 10px;
    font-size: 20px;
    color: gray;
    cursor: pointer;

}
.new-order .order-data .payment{
    margin-top: 20px;
    padding: 10px;
    border-radius: 10px;
    background-color: white;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);
}
.new-order .order-data .payment .payment-info{
    display: grid;
    grid-template-columns: 2fr 1fr 1fr;
    grid-gap: 10px;
    align-items: center;
}
.new-order .order-data .payment .payment-info p{
    margin-bottom: 15px;
    font-size: 13px;
    color: rgb(105, 105, 105);
    font-weight: 500;
}
.new-order .order-data .payment .payment-info .right{
    text-align: right;
}
.new-order .order-data .payment .payment-info input{
    width: 80px;
    padding: 5px;
}
.new-order .order-data .data-right .notes{
    background-color: white;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);
    margin-bottom: 20px;

}

.new-order .order-data .data-right .notes input{
    padding: 8px;
    border: 1px solid rgb(39, 39, 39);
    border-radius: 5px;
    width: 100%;
}
.new-order .order-data .data-right .notes select{
    padding: 8px;
    border: 1px solid rgb(39, 39, 39);
    border-radius: 5px;
    width: 100%;
}
.new-order .order-data .data-right .customer-detail input{
    margin-bottom: 5px;
}
.filter-btn{
    margin-top: 20px;
    padding: 8px 20px;
    cursor: pointer;
    scale: 0.9;
    border: none;
    background-color: rgb(74, 74, 255);
    border-radius: 5px;
    color: white;
    font-weight: 500;
    font-size: 11px;
    letter-spacing: 1px;
    box-shadow: 0 0px 0px 0 rgba(74, 74, 255, 0.300), 0 3px 5px 0 rgba(74, 74, 255, 0.300);
}
.new-order button{
    cursor: pointer;
    padding: 8px 10px;
    scale: 0.9;
    border: none;
    background-color: rgb(74, 74, 255);
    border-radius: 5px;
    color: white;
    font-weight: 500;
    font-size: 11px;
    letter-spacing: 1px;
    box-shadow: 0 0px 0px 0 rgba(74, 74, 255, 0.300), 0 3px 5px 0 rgba(74, 74, 255, 0.300);
}
.dialog .dialog-content{
    min-width: 45vw;
}
.dialog .search-dialog{
   
    display: flex;
    align-items: center;
    background-color: rgb(241, 241, 241);
    border-radius: 5px;
    padding: 8px;
}
.dialog .search-dialog .search-icon{
    font-size: 18px;
    margin-right: 5px;
    color: rgb(166, 166, 166);
}
.dialog .search-dialog input{
    outline: none;
    border: none;
    background: transparent;
    width: 100%;
}
.new-order .order-data .data-right .notes .customer-detail{
    margin: 20px 0;
}
.new-order .order-data .data-right .notes .customer-detail p{
    margin-top: 10px;
    font-size: 13px;
    font-weight: 500;
}
.dialog .product-list{
    height: 300px;
    overflow-y: scroll;
    padding: 10px;
    font-family: var(--heading-font);
    
}
.dialog .product-list .product-main{
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}
.dialog .product-list .list-product{
    padding: 10px 0;
    border-bottom: 1px solid rgb(220, 220, 220);
}
.dialog .product-list .list-product .product-drop{
    margin-left: 60px;
    margin-top: 20px;
}
.dialog .product-list .list-customer{
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    grid-gap: 10px;
    padding: 10px;
    border-bottom: 1px solid rgb(219, 219, 219);
    font-size: 12px;
    font-weight: 500;
    cursor: pointer;
}
.dialog .product-list .list-product .product-drop ul li{
    margin-bottom: 10px;
    cursor: pointer;
    font-size: 13px;
    display: grid;
    grid-template-columns:0.2fr 2fr 1fr 1fr;
    border-bottom: 1px solid rgb(220, 220, 220);
    grid-gap: 10px;
    padding: 5px 0;
}
.dialog .product-list .product-main img{
    width: 50px;
    height: 50px;
    border-radius: 5px;
    border: 1px solid gray;
}
.dialog .product-list .product-main .drop-icon{
    margin-right: 10px;
    width: 20px;
    height: 20px;
    /* background-color: rgb(232, 232, 232); */
    border: 1px solid gray;
    display: flex;
    justify-content: center;
    align-items: center;
    color: gray;
    border-radius: 50%;
}

.dialog .product-list .product-main .drop-icon:hover{
    box-shadow: 0 2px 4px 0 rgba(121, 121, 121, 0.2), 0 3px 10px 0 rgba(92, 92, 92, 0.19);

}

.dialog .product-list .product-main div{
    display: flex;
    align-items: center;
    margin-right: 10px;
}

.dialog .product-list .product-main div p{
    font-size: 12px;
    font-weight: 600;
    margin-left: 10px;
}
.packing-slip .head{
    display: flex;
    justify-content: space-between;
}
.packing-slip .head h1{
    font-size: 25px;
    letter-spacing: 2px;
}
.packing-slip .head p{
    margin-bottom: 2px;
    font-size: 12px;
}
.packing-slip p{
    line-height: normal;
}
.packing-slip .detail{
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    margin-top: 10px;
}
.packing-slip .detail p{
    margin-bottom: 3px;
    font-size: 12px;
}
.packing-slip .head{
    
        margin-bottom: 2px;
        font-size: 12px;    
}
.packing-slip .item{
    display: flex;
    justify-content: space-between; 
    margin-top: 17px;
}
.packing-slip .item img{
    width: 70px;
    height: 70px;
}
.packing-slip .img{
    display: flex;
}
.packing-slip .item p{
    font-size: 12px;
}
.packing-slip .produ-detail p{
    margin-bottom: 2px;
    font-size: 12px;
    margin-left: 10px;

}
.packing-slip .total-amount{
    margin-top: 10px;
}
.packing-slip .total-amount p{
    margin-bottom: 2px;
    font-size: 12px;

}
.packing-slip .shop {
   display: flex;
   align-items: center;
   justify-content: center;
   flex-direction: column;
}
.packing-slip .shop p{
    margin-bottom: 2px;
    font-size: 12px;
}


.single-order .message{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 15px;
    margin: 50px 0;
}
.single-order .message .msg{
    border: 1px solid rgb(210, 210, 210);
    padding: 10px 5px;
    border-radius: 5px;

}
.single-order .message .msg .comments{
    margin-top: 20px;
}
.single-order .message .msg .comments p{
    font-size: 15px;
    font-weight: 500;
}
.single-order .message .msg .comments .comment-box{
    background-color: white;
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 10px;
}
.single-order .message .msg .comments .comment-box .msg-c{
    margin-top: 10px;
    font-weight: 400;

}
.single-order .message .msg .comments .comment-box .name{
    display: flex;
    align-items: center;
}
.single-order .message .msg .comments .comment-box .name .time{
    margin-left: 15px;
    font-size: 13px;
    
}
.single-order .history{
    border: 1px solid rgb(210, 210, 210);
    padding: 10px 5px;
    border-radius: 5px;
}
.single-order .history .history-box{
    background-color: white;
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 10px;
}
.single-order .history .history-box .msg-c{
    margin-top: 10px;
    font-weight: 400;

}
.single-order .history .history-box .name{
    display: flex;
    align-items: center;
}
.single-order .history .history-box .name .time{
    margin-left: 15px;
    font-size: 13px;
    
}
.single-order .message .form{
    background-color: white;
    padding: 10px;
    margin: 10px 0;
}
.single-order .message .form input{
    padding: 8px;
    background-color: rgb(238, 238, 238);
    border-radius: 8px;
    border: none;
    outline: none;
    width: 100%;
    margin: 10px 0;
}
.single-order .message .form button{
    padding: 8px 20px;
    cursor: pointer;
    scale: 0.9;
    border: none;
    background-color: rgb(74, 74, 255);
    border-radius: 5px;
    color: white;
    font-weight: 500;
    font-size: 11px;
    letter-spacing: 1px;
    box-shadow: 0 0px 0px 0 rgba(74, 74, 255, 0.300), 0 3px 5px 0 rgba(74, 74, 255, 0.300);
}

.search2{
    display: none;
}
@media screen and (max-width:1450px) {
    .orders .product-table{
        padding: 5px 0;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 10px;

    
    }
}
@media screen and (max-width:1250px) {
    .orders .product-table{
        padding: 5px 0;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 10px;

    
    }
}
@media screen and (max-width:950px) {
    .orders .product-table{
        padding: 5px 0;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 5px;

    
    }
    .orders .search1{
        display: none;
    }
    .orders .search2{
        display: flex;
    }
    .single-order .order-detail{
        grid-template-columns:1fr;
    }
}
@media screen and (max-width:650px) {
.orders .head{
    flex-direction: column;
}
    .orders .product-table{
        padding: 5px 0;
        grid-template-columns: repeat(1, 1fr);
        grid-gap:15px;

    
    }
}
