.dashboard .table table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }
  
.dashboard .table td, th {
    padding: 10px 5px;
  }

.dashboard .table tr{
    border-bottom: 1px solid rgb(225, 225, 225);
    font-family: 500;
    margin-bottom: 10px;
}
.dashboard .table tr td:nth-child(1){
    text-transform: uppercase;
    font-weight: 600;
}
.dashboard .table tr td:nth-child(2){
    color: rgb(13, 120, 250);
}
.dashboard .table tr td:nth-child(2){
    color: rgb(17, 199, 0);
}
.dashboard{
    padding: 20px 10px;
}  

.dashboard .top{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 10px;
}  
.dashboard .top .total{
    padding:10px;
    height: 150px;
    display: flex;
    flex-direction: column;
    border: 3px solid white;

}
.dashboard .top .total .data-div{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.dashboard .top .total .data-div div{
    display: flex;
    align-items: center;
}
.dashboard .top .total .data-div .select{
    height: 30px;
    /* background-color: transparent; */
    border: 2px solid white;
    padding: 5px ;
    color: rgb(2, 2, 2);
    
}
.dashboard .top .total .data-div .select input{
    background-color: red;
    border: 1px solid red;
    z-index: 5;
}
.dashboard .top .total div .data{
    margin-left: 10px;
    color: white;
    font-weight: 500;
    font-size: 20px;
    font-family: var(--heading-font);

}
.dashboard .top .total:nth-child(1){
    background-color:rgb(255, 162, 0);
}
.dashboard .top .total:nth-child(2){
    background-color:rgb(0, 136, 255);
}
.dashboard .top .total:nth-child(3){
    background-color:rgb(255, 0, 0);
}
.dashboard .top .total:nth-child(3) input{
    padding: 5px;
    /* background-color: transparent; */
    border: 1px solid white;
    cursor: pointer;
    width: 100px;
    margin: 0 3px;
    /* color: white; */
}
.dashboard .top .total:nth-child(3) button{
    background-color: black;
    color: white;
    padding: 6px 10px;
    border: none;
    cursor: pointer;
}
.dashboard .top .total .flexy{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin-top: 30px;
    color: white;
}
.dashboard .top .total .flexy div:nth-child(2){
    text-align: center;
}
.dashboard .top .total .flexy div p:nth-child(1){
    margin-bottom: 10px;
    font-size: 14px;

}
.dashboard .top .total .flexy div p:nth-child(2){
    margin-bottom: 2px;
    font-weight: 500;
    letter-spacing: 1px;
}
.dashboard .top .total .flexy div:nth-child(3){
    text-align: right;
}

.dashboard .top .total .topIcon{
    font-size: 30px;
    color: white;
}
.dashboard .bottom{
    margin-top: 20px;
}
.dashboard .bottom .orders{
    background-color: rgb(255, 255, 255);
    padding: 20px;

}
.dashboard .bottom .orders .table tr{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
}


@media screen and (max-width:950px) {
    .dashboard .top{
        grid-template-columns: 1fr;
    }  
    .dashboard .bottom{
        grid-template-columns: 1fr;
    }
    .dashboard .chats{
        grid-template-columns: 1fr 1fr;
    }
}
@media screen  and (max-width:650px){
    .dashboard .top .total .flexy div p{
        font-size: 16px;
        letter-spacing: 1px;
        margin-bottom: 7px;
        display: flex;
        align-items: center;
    }
    .dashboard .top .total .flexy div p span:nth-child(2){
        margin-left: 10px;
    }
    .dashboard .chats{
        grid-template-columns: 1fr ;
    }
    .dashboard .bottom .orders .table tr{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }
}